export const messages: Record<string, Record<string, string | undefined>> = {
	de: {
		Manage: 'Verwalten',
		'Prisma Access Agent Manager': undefined,
		Inventory: 'Inventar'
	},
	fr: {
		Manage: 'Gestion',
		'Prisma Access Agent Manager': undefined,
		Inventory: 'Inventaire'
	},
	ja: {
		Manage: '管理',
		'Prisma Access Agent Manager': undefined,
		Inventory: 'インベントリ'
	}
}
